<div
  class="custom-repeater-collapsible d-flex flex-col card mb-3 border-1 border-info-subtle rounded-1 p-1">
  <p *ngIf="to?.label">
    {{ to.label }}
  </p>

  <div
    *ngFor="let field of field.fieldGroup; let i = index"
    class="row justify-content-between m-1">
    <div class="d-flex col-12 mb-1">
      <mat-accordion class="shadow rounded card col-11">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{
                field.formControl?.get(to['titleFieldName'])?.value ||
                  to.label + ' ' + (i + 1)
              }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <formly-field [field]="field"></formly-field>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="col-1 d-flex justify-content-center align-items-center">
        <button class="btn btn-danger" type="button" (click)="remove(i)">
          -
        </button>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <button class="btn btn-secondary btn-sm" type="button" (click)="add()">
      {{ to['addText'] }}
    </button>
  </div>
</div>
