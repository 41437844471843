import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VerticalStepperService {
  private selectedIndexSubject$ = new BehaviorSubject<number>(0);
  selectedIndexObservable$ = this.selectedIndexSubject$.asObservable();
  private draftSavingModel$ = new BehaviorSubject<any>({});
  draftSavingModelObservable$ = this.draftSavingModel$.asObservable();
  private draftIsSaving$ = new BehaviorSubject<boolean>(false);
  draftIsSavingObservable$ = this.draftIsSaving$.asObservable();

  setSelectedIndex(index: number) {
    this.selectedIndexSubject$.next(index);
  }

  triggerDraftSaving(model: any) {
    this.draftSavingModel$.next(model);
  }

  triggerDraftSavingBoolean(toggle: boolean) {
    this.draftIsSaving$.next(toggle);
  }

  resetStepper() {
    this.selectedIndexSubject$.next(0);
  }
}
