<div class="applications-list">
  <div
    class="results col-12 tab-pane fade show active"
    id="nav-apps"
    role="tabpanel">
    <!-- Search Applications -->
    <div
      class="input-group mb-4 col-12 col-md-6 col-lg-4"
      *ngIf="!isLoadingApplications && totalApplications !== 0">
      <span class="input-group-text bg-white search-input"
        ><i class="icon-search-lg"></i
      ></span>
      <input
        type="text"
        class="form-control search-input col-12 col-md-6 col-lg-4"
        [placeholder]="'Search by application name or bill ID' | translate"
        [(ngModel)]="searchTerm"
        (keyup.enter)="onEnterInSearchInput()" />
    </div>
    <h2 class="result-header d-lg-none">
      {{ 'Applications' | translate }}
    </h2>
    <!-- Results View -->
    <div class="content-list d-flex flex-column">
      <ng-container
        *ngIf="showMobileApplicationList; else tableApplicationList">
        <div class="row g-3">
          <div
            class="col-md-6"
            *ngFor="let application of applicationsList; index as index">
            <div class="application">
              <div class="application-details">
                <div class="application-number">
                  {{ application.applicationNumber }}
                </div>
                <span class="service-name">{{
                  application.serviceName | translate
                }}</span>
              </div>
              <div
                class="application-status irembo-badge"
                [ngClass]="
                  getApplicationStatusToColor(application.applicationStatus)
                ">
                <div
                  class="pulse"
                  [ngClass]="
                    getApplicationStatusPulseToBackgroundColor(
                      application.applicationStatus
                    )
                  "></div>
                {{ getApplicationStatusToLabel(application.applicationStatus) }}
              </div>
              <div class="divider"></div>
              <div class="application-view">
                <a
                  type="button"
                  (click)="viewApliactionDetails(application)"
                  class="text-decoration-none"
                  >{{ 'View details' | translate }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <!-- Pagination -->
        <irembogov-citizen-pagination
          [minimalPagination]="true"
          [collectionSize]="totalApplications"
          (paginatedValues)="handlePaginate($event)"
          [showDirectionText]="false"></irembogov-citizen-pagination>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #tableApplicationList>
  <div class="table-responsive">
    <div
      *ngIf="isLoadingApplications"
      class="d-flex justify-content-center mt-5">
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <table class="table table-sm" *ngIf="!isLoadingApplications">
      <thead>
        <tr>
          <th>{{ 'Application No' | translate }}</th>
          <th>{{ 'Service' | translate }}</th>
          <th *ngIf="viewType === 'business'">
            {{ 'Applicant Name' | translate }}
          </th>
          <th>{{ 'Date Submitted' | translate }}</th>
          <th>{{ 'Last Update' | translate }}</th>
          <th>{{ 'Status' | translate }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let application of applicationsList">
          <td>{{ application.applicationNumber }}</td>
          <td>{{ application.serviceName | translate }}</td>
          <td *ngIf="viewType === 'business'">
            {{ application.applicantName }}
          </td>
          <td>
            {{
              application.dateCreated
                ? (convertDate(application.dateCreated) | date : 'short')
                : '-'
            }}
          </td>
          <td>
            {{
              application.lastUpdated
                ? (convertDate(application.lastUpdated) | date : 'short')
                : '-'
            }}
          </td>
          <td>
            <div
              class="application-status irembo-badge"
              [ngClass]="
                getApplicationStatusToColor(application.applicationStatus)
              ">
              <div
                class="pulse"
                [ngClass]="
                  getApplicationStatusPulseToBackgroundColor(
                    application.applicationStatus
                  )
                "></div>
              {{
                getApplicationStatusToLabel(application.applicationStatus)
                  | translate
              }}
            </div>
          </td>
          <td>
            <div class="application-view fw-semibold">
              <a
                type="button"
                (click)="viewApliactionDetails(application)"
                class="text-decoration-none"
                >{{ 'View' | translate }}</a
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="w-100 mt-3 px-4">
      <irembogov-citizen-pagination
        [minimalPagination]="false"
        [showDirectionText]="true"
        [collectionSize]="totalApplications"
        (paginatedValues)="
          handlePaginate($event)
        "></irembogov-citizen-pagination>
    </div>
  </div>
</ng-template>
