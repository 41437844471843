import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'irembogov-custom-expansion-repeater',
  templateUrl: './custom-expansion-repeater.component.html',
  styleUrls: ['./custom-expansion-repeater.component.scss'],
})
export class CustomExpansionRepeaterComponent extends FieldArrayType {
  constructor() {
    super();
  }
}
