import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  Subscription,
} from 'rxjs';
import {
  EGenericInputTriggerType,
  GenericInputStatusEnum,
} from '../../../models/irembo-generic-input-status.enum';
import { IEnvironment } from '../../../models/environment.model';
import { v4 as uuidv4 } from 'uuid';
import {
  checkForValidFields,
  configureFields,
  populateFormFields,
  updateUrlWithApiGatewayBaseUrl,
  getPopulateReferenceForm,
  subscribeToResetFieldFetchData,
  findFormControl,
  handleErrorMapping,
  isErrorResponse,
} from '../../../../utils/utils/data-fetch-widget-utils';
import { FormStateService } from '../../../services/formly/form-state.service';
import { FormlyValueChangeEvent } from '@ngx-formly/core/lib/models';
import { AbstractControl } from '@angular/forms';

export interface ListenFieldConfig {
  key: string;
  mapTo?: string;
}
@Component({
  selector: 'irembogov-custom-generic-data-fetch',
  templateUrl: './custom-generic-data-fetch.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CustomGenericDataFetchComponent
  extends FieldType<FieldTypeConfig>
  implements OnDestroy, AfterViewInit
{
  private subscriptions = new Subscription();
  statusClass: string | undefined;
  fieldsToPopulate: Record<string, string>[] = [];
  private environment: IEnvironment;

  constructor(
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private formStateService: FormStateService,
    @Inject('environment') environment: IEnvironment
  ) {
    super();
    this.environment = environment;
  }

  setupFieldListeners() {
    const { payloadKeyMappings, useMultiplePayloadKeys, payloadKey } =
      this.field.props;
    const debounceMs = this.field.props['debounceTime'] || 500;
    const formRef = getPopulateReferenceForm(this.field, this.form);

    if (
      useMultiplePayloadKeys &&
      !(Array.isArray(payloadKeyMappings) || payloadKeyMappings.length)
    )
      return;

    if (!useMultiplePayloadKeys) {
      const control = findFormControl(formRef, payloadKey);
      if (control) {
        this.subscriptions.add(
          control.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(debounceMs))
            .subscribe(value => {
              if (value !== null && value !== undefined) {
                this.onDataFetch(value);
              }
            })
        );
        return;
      }
    }

    const fieldControls: AbstractControl[] = [];
    const fieldConfigs: any[] = [];

    payloadKeyMappings.forEach((mapping: any) => {
      if (!mapping.isStatic && mapping.sourceField) {
        const control = findFormControl(formRef, mapping.sourceField);
        if (control) {
          fieldControls.push(control);
          fieldConfigs.push(mapping);
        }
      }
    });

    if (!fieldControls.length) return;

    const fieldObservables = fieldControls.map(control =>
      control.valueChanges.pipe(
        distinctUntilChanged(),
        debounceTime(debounceMs)
      )
    );

    this.subscriptions.add(
      combineLatest(fieldObservables).subscribe(values => {
        if (values.every(v => v !== null && v !== undefined)) {
          const formRef = getPopulateReferenceForm(this.field, this.form);
          const firstValue = findFormControl(
            formRef,
            fieldConfigs[0].sourceField
          )?.value;
          this.onDataFetch(firstValue);
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      subscribeToResetFieldFetchData(this.field, this.formStateService)
    );

    if (
      this.field.props['triggerType'] ===
      EGenericInputTriggerType.FIELD_LISTENER
    ) {
      this.setupFieldListeners();
    }

    this.subscriptions.add(
      this.field.options?.fieldChanges
        ?.asObservable()
        .pipe(
          filter((change: FormlyValueChangeEvent) => {
            if (
              change.field.key === this.field.key &&
              change.type === 'valueChanges' &&
              this.field.props['triggerType'] ===
                EGenericInputTriggerType.TRIGGER_BUTTON &&
              change.value
            ) {
              this.formControl.setErrors({
                ...this.formControl.errors,
                notVerifiedByTriggerAction: true,
              });
            }
            return (
              change.field.key === this.field.key &&
              change.type === 'expressionChanges' &&
              change['property'] === 'props.runningAction'
            );
          }),
          distinctUntilChanged(
            (a: FormlyValueChangeEvent, b: FormlyValueChangeEvent) =>
              a.value === b.value
          )
        )
        ?.subscribe((change: FormlyValueChangeEvent) => {
          if (change.value && this.field.formControl.value) {
            this.onDataFetch(this.field.formControl.value);
            return;
          }
          this.field.props['runAction'] = false;
        })
    );

    checkForValidFields(this.field);

    this.fieldsToPopulate = this.field.props?.['populates'];
    if (this.fieldsToPopulate?.length > 0) {
      const formRef = getPopulateReferenceForm(this.field, this.form);
      this.subscriptions.add(
        configureFields(this.field, this.fieldsToPopulate, formRef)
      );
    }
  }

  buildPayload(inputValue: string): Record<string, unknown> {
    const {
      useMultiplePayloadKeys,
      payloadKey,
      payloadKeyMappings,
      triggerType,
    } = this.field.props;
    const formRef = getPopulateReferenceForm(this.field, this.form);

    // Handle non-field-listener cases with existing logic
    if (triggerType !== EGenericInputTriggerType.FIELD_LISTENER) {
      if (!useMultiplePayloadKeys || !payloadKeyMappings?.length) {
        return { [payloadKey]: inputValue };
      }

      const payload: Record<string, unknown> = {};
      payloadKeyMappings.forEach((mapping: any) => {
        if (mapping.isStatic) {
          payload[mapping.key] = mapping.staticValue;
        } else if (mapping.sourceField) {
          const sourceValue = findFormControl(
            formRef,
            mapping.sourceField
          )?.value;
          payload[mapping.key] = sourceValue;
        } else {
          payload[mapping.key] = inputValue;
        }
      });
      return payload;
    }

    if (!useMultiplePayloadKeys) {
      return { [payloadKey]: formRef.get(payloadKey)?.value };
    }

    const payload: Record<string, unknown> = {};
    payloadKeyMappings.forEach((mapping: any) => {
      if (mapping.isStatic) {
        payload[mapping.key] = mapping.staticValue;
      } else if (mapping.sourceField) {
        payload[mapping.key] = formRef.get(mapping.sourceField)?.value;
      }
    });

    return payload;
  }

  onDataFetch(inputValue: string) {
    let { url } = this.field.props;
    const { endpointCode } = this.field.props;

    if (!url || !endpointCode) {
      throw new Error('url and endpointCode properties are required');
    }

    if (this.field.props['useBaseUrl']) {
      url = updateUrlWithApiGatewayBaseUrl(url, this.environment);
    }

    const payload = this.buildPayload(inputValue);

    const params = {
      endpointCode,
      callerId: uuidv4(),
      payload,
      requester: uuidv4(),
    };

    this.statusClass = GenericInputStatusEnum.FETCHING;
    this.subscriptions.add(
      this.http
        .post<Record<string, unknown>>(url, params)
        .pipe(
          finalize(() => {
            if (
              this.field.props?.['triggerType'] ===
              EGenericInputTriggerType.TRIGGER_BUTTON
            ) {
              this.field.props['runAction'] = false;
            }
          })
        )
        .subscribe({
          next: (res: Record<string, unknown>) => {
            const { isError } = isErrorResponse(
              res,
              this.field.props['errorMapping']
            );
            if (isError) {
              const { errors, statusClass } = handleErrorMapping(
                res,
                this.field.props['errorMapping'],
                'invalidGenericInput',
                this.field,
                this.formControl
              );

              this.statusClass = statusClass;
              this.formControl.setErrors(errors);
              return;
            }

            // Success handling
            this.statusClass = GenericInputStatusEnum.SUCCESS;
            const data = res['data'] as Record<string, unknown>;
            const response = data['response'] as string;

            this.formStateService.saveFetchedDataKeyInFormState(
              this.field,
              response
            );

            if (this.fieldsToPopulate.length > 0) {
              const formRef = getPopulateReferenceForm(this.field, this.form);
              populateFormFields(response, this.field, formRef);
            }
            delete this.formControl.errors?.['notVerifiedByTriggerAction'];
            this.field.formControl.updateValueAndValidity();
            this.cd.detectChanges();
          },
          error: error => {
            const { errors, statusClass } = handleErrorMapping(
              error?.error || error,
              this.field.props['errorMapping'],
              'invalidGenericInput',
              this.field,
              this.formControl,
              true
            );

            this.statusClass = statusClass;
            this.formControl.setErrors(errors);
          },
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
