import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { unflatten } from 'flat';
import { BehaviorSubject } from 'rxjs';
import { deepFlatten } from '../../../utils/utils/deep-flatten.utils';
import { EIremboFormlyFormStateKeys } from '../../models/irembo-formly-formstate-keys.enum';

@Injectable({
  providedIn: 'root',
})
export class FormStateService {
  private formlyFormState$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private intializeRepeater$ = new BehaviorSubject<Record<string, unknown>>({});
  intializeRepeaterSource$ = this.intializeRepeater$.asObservable();

  getFormlyFormStateSubject(): BehaviorSubject<any> {
    return this.formlyFormState$;
  }

  getCurrentFormlyFormState(): any {
    return this.formlyFormState$.value;
  }

  setFormlyFormState(formState: any): void {
    this.formlyFormState$.next({ ...formState });
  }

  partialUpdateFormlyFormState(formStatePartial: any): void {
    this.formlyFormState$.next({
      ...this.getCurrentFormlyFormState(),
      ...formStatePartial,
    });
  }

  saveFetchedDataKeyInFormState = (field: FormlyFieldConfig, data: any) => {
    let fetchedData = undefined;
    try {
      let jsonData: Record<string, unknown> | undefined = undefined;
      if (data && typeof data === 'string') {
        jsonData = JSON.parse(data);
      }

      if (data && typeof data === 'object') {
        jsonData = data as Record<string, unknown>;
      }

      if (jsonData !== undefined) {
        const flattenedData = deepFlatten(jsonData);
        fetchedData = unflatten(flattenedData);
      } else {
        fetchedData = data;
      }
    } catch (e) {
      fetchedData = data;
    }
    if (field.formControl?.invalid) {
      this.deleteFetchedDataKeyInFormState(<string>field.key);
      return;
    }
    let fetchedDataAsJson: Record<string, unknown> = {};
    if (fetchedData && typeof fetchedData === 'string') {
      fetchedDataAsJson = JSON.parse(fetchedData);
    }

    if (fetchedData && typeof fetchedData === 'object') {
      fetchedDataAsJson = fetchedData as Record<string, unknown>;
    }

    const formStateFetchedData: any =
      this.getCurrentFormlyFormState()?.[
        EIremboFormlyFormStateKeys.FETCHED_DATA
      ] ?? {};

    formStateFetchedData[<string>field.key] = fetchedDataAsJson;

    this.partialUpdateFormlyFormState({
      [EIremboFormlyFormStateKeys.FETCHED_DATA]: formStateFetchedData,
    });
  };

  checkIfApplicationInAnRFAState = (): boolean => {
    const applicationState =
      this.getCurrentFormlyFormState()?.[
        EIremboFormlyFormStateKeys.APPLICATION_STATE
      ];
    if (!applicationState) {
      return false;
    }
    return applicationState?.includes?.('PENDING_RESUBMISSION');
  };

  deleteFetchedDataKeyInFormState = (fieldKey: string) => {
    const currentFormState = this.getCurrentFormlyFormState();
    delete currentFormState?.[EIremboFormlyFormStateKeys.FETCHED_DATA]?.[
      <string>fieldKey
    ];
    this.setFormlyFormState(currentFormState);
  };

  intializeRepeater(value: Record<string, unknown>) {
    this.intializeRepeater$.next(value);
  }
}
