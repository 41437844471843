import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { firstValueFrom, map } from 'rxjs';
import { ILocale } from '../../../app/models/locale/locale.model';
import { LocaleService } from '../../../app/services/locale.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GenericContainerResolver implements Resolve<void> {
  activeLocale = '';
  passedLocale: string | null | undefined;

  constructor(
    public translate: TranslateService,
    private localeService: LocaleService,
    private toastService: ToastService,
    private keycloakService: KeycloakService
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<void> {
    this.passedLocale = await this.getPassedLocale(route);

    const supportedLocales = await this.getSupportedLocales();

    this.localeService.setSupportedLocales(supportedLocales);
    this.setQueryLocale(supportedLocales);
    this.translate.addLangs(supportedLocales.map(lang => lang.locale));
  }

  async getSupportedLocales(): Promise<ILocale[]> {
    try {
      const response = await firstValueFrom(
        this.localeService
          .getSupportedLocales()
          .pipe(map(res => res.data.content))
      );
      return response;
    } catch (error: any) {
      this.toastService.show({
        body: error.error.responseMessage ?? error.error.message,
        type: 'error',
        delay: 5000,
      });
      return [];
    }
  }

  setQueryLocale(supportedLocales: ILocale[]) {
    if (this.passedLocale) {
      const filteredLocales = supportedLocales.filter(
        lang =>
          lang.name === this.passedLocale ||
          lang.nativeName === this.passedLocale ||
          lang.locale === this.passedLocale
      );

      if (filteredLocales.length > 0) {
        this.activeLocale = filteredLocales[0].locale;

        this.translate.use(this.activeLocale);
        this.localeService.setActiveLocale(filteredLocales[0]);

        localStorage.setItem('locale', this.activeLocale);
        localStorage.setItem('localeName', filteredLocales[0].nativeName);
      }
    }
  }

  async getLocaleFromToken(): Promise<string | null> {
    const accessToken = await this.keycloakService.getToken();
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(accessToken);

    if (decodedToken && decodedToken['locale']) {
      return decodedToken['locale'];
    }
    return null;
  }

  async getPassedLocale(route: ActivatedRouteSnapshot): Promise<string> {
    const routeLocale = route.queryParamMap.get('locale');
    const tokenLocale = await this.getLocaleFromToken();

    return routeLocale
      ? routeLocale
      : tokenLocale
      ? tokenLocale
      : localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;
  }
}
