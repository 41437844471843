<div class="op-application-details-container">
  <div class="row">
    <div class="col">
      <div class="application-details">
        <h1
          class="title tt_norms500"
          (click)="isCollapsed = !isCollapsed"
          *ngIf="showTitle">
          {{ 'Application Summary' | translate }}
          <span>
            <img
              class="me-1"
              src="/assets/svg-icons/{{
                isCollapsed ? 'eye' : 'eye-slash'
              }}.svg"
              alt="" />
            {{
              isCollapsed
                ? ('View details' | translate)
                : ('Hide details' | translate)
            }}</span
          >
        </h1>
        <div [(ngbCollapse)]="isCollapsed" class="mt-4">
          <ng-container
            *ngFor="
              let data of applicationSummary;
              let index = index;
              trackBy: trackByFn
            ">
            <h1 class="form-preview-header tt_norms500 mb-3">
              {{ index + 1 + '.' }} {{ data.formGroupName | translate }}
            </h1>
            <irembogov-application-form-preview-card
              [applicationId]="application?.applicationId"
              [data]="data"
              [columnSize]="'col-md-6 mb-3'"
              [applicationBaseUrl]="applicationBaseUrl"
              [applicationPath]="
                applicationPath
              "></irembogov-application-form-preview-card>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
