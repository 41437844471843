import { IService } from './../../models/services-list.model';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicServicesService } from '../../services/public-services.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, tap } from 'rxjs';

@Component({
  selector: 'irembogov-service-search-page',
  templateUrl: './service-search-page.component.html',
  styleUrls: ['./service-search-page.component.scss'],
})
export class ServiceSearchPageComponent implements OnInit, OnDestroy {
  keyword = '';
  searchby = '';

  showMore = false;
  modalID!: ElementRef;
  featuredServices: IService[] = [];
  selectedApplication: IService;

  totalItems: number | undefined;
  currentPage: number | undefined;
  itemsPerPage: number | undefined;

  orgFilters: string[] = [];

  searchResults: IService[] | undefined;
  private subscriptions = new Subscription();

  selectedOrgFilter = 'all';
  filteredSearchResults: IService[] = [];

  page = 0;
  size = 10;

  constructor(
    private route: ActivatedRoute,
    private publicServicesService: PublicServicesService,
    private modalService: NgbModal
  ) {
    this.selectedApplication = {
      id: '',
      serviceName: '',
      serviceSummary: '',
      organization: {
        id: '',
        acronym: '',
        name: '',
      },
      priceType: '',
      price: 0,
      currency: '',
      expectedProcessingTime: 0,
      expectedProcessingMeasurementUnit: '',
      category: {
        id: '',
        name: '',
      },
      applicantType: '',
    };
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(res => {
      this.keyword = res['keyword'] ? res['keyword'] : '';

      if (this.keyword) {
        this.searchByKeyword(this.keyword, this.page, this.size);
      }
    });
  }

  onResultSelected(value: IService) {
    this.searchByKeyword(value.serviceName, this.page, this.size);
  }
  onKeyPresedSelected(value: string) {
    console.log(`Enter`);

    this.searchByKeyword(value, this.page, this.size);
  }

  openModal(modal: ElementRef) {
    this.modalService.open(modal, {
      size: 'lg',
      centered: true,
      animation: true,
    });
  }

  handleApply(modal: ElementRef, service: IService) {
    this.selectedApplication = service;
    this.openModal(modal);
  }

  searchByKeyword(keyword: string, page: number, size: number) {
    const formattedKeyword = keyword.toLowerCase().trim();
    this.subscriptions.add(
      this.publicServicesService
        .getServices({
          page,
          size,
          query: formattedKeyword,
        })
        .pipe(
          tap(res => {
            this.orgFilters = res.data.content
              .map(ele => ele.organization.name)
              .filter((value, index, self) => self.indexOf(value) === index);
          })
        )
        .subscribe(res => {
          this.totalItems = res.data.totalElements;
          this.currentPage = res.data.pageable.pageNumber;
          this.itemsPerPage = res.data.pageable.pageSize;
          this.searchResults = res.data.content;
          this.resetFilter();
        })
    );
  }

  filterByOrg(orgName: string) {
    if (this.searchResults) {
      this.filteredSearchResults = this.searchResults.filter(
        ele => ele.organization.name === orgName
      );
      this.selectedOrgFilter = orgName;
    }
  }

  handlePaginate(event: { pageNumber: number; pageSize: number }) {
    this.page = event.pageNumber - 1;
    this.size = event.pageSize;
    if (this.keyword) {
      this.searchByKeyword(this.keyword, this.page, this.size);
    }
  }

  resetFilter() {
    this.filteredSearchResults = [];
    this.selectedOrgFilter = 'all';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
