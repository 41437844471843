import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { distinctUntilChanged, finalize, Subscription } from 'rxjs';
import { EGenericInputTriggerType } from '../../../models/irembo-generic-input-status.enum';
import { IEnvironment } from '../../../models/environment.model';
import { v4 as uuidv4 } from 'uuid';
import {
  checkForValidFields,
  configureFields,
  populateFormFields,
  updateUrlWithApiGatewayBaseUrl,
  getPopulateReferenceForm,
  subscribeToResetFieldFetchData,
} from '../../../../utils/utils/data-fetch-widget-utils';
import { FormStateService } from '../../../services/formly/form-state.service';
import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';
import {
  defaultOtpDataFetchInputErrorMessages,
  EOtpDataFetchStatus,
} from '../../../config/custom-id-input-api-urls.config';
import { IIrembogovBasicLabelKeyPair } from '../../../models/irembogov-basic-label-keypair.model';

@Component({
  selector: 'irembogov-custom-custom-otp-data-fetch',
  templateUrl: './custom-otp-data-fetch.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CustomOtpDataFetchComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(NgOtpInputComponent, { static: false })
  disableAutoFocus = false;

  isRequestingSendOtp = false;
  isVerifyingOtp = false;
  isFetchingData = false;
  otpInputConfig!: NgOtpInputConfig;

  EGenericInputTriggerType = EGenericInputTriggerType;
  EOtpDataFetchStatus = EOtpDataFetchStatus;
  dataFetchedFailedError = false;

  private readonly subscriptions = new Subscription();
  fieldStatus: EOtpDataFetchStatus = EOtpDataFetchStatus.INPUT_RECIPIENT;
  fieldsToPopulate: Record<string, string>[] = [];
  private readonly environment: IEnvironment;

  constructor(
    private readonly http: HttpClient,
    private readonly cd: ChangeDetectorRef,
    private readonly formStateService: FormStateService,
    @Inject('environment') environment: IEnvironment
  ) {
    super();
    this.environment = environment;
  }

  ngOnInit(): void {
    if (this.environment?.disableAutoFocusOnCustomGenericFetchWithOTP) {
      this.disableAutoFocus = true;
    }
    this.generateDefaultErrorMessages();
    this.cd.detectChanges();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      subscribeToResetFieldFetchData(this.field, this.formStateService)
    );
    this.subscriptions.add(
      this.formControl.valueChanges
        .pipe(distinctUntilChanged())
        ?.subscribe((value: string) => {
          this.formControl.setValue(value);
          this.fieldStatus = EOtpDataFetchStatus.INPUT_RECIPIENT;
          this.cd.detectChanges();
        })
    );

    checkForValidFields(this.field);

    this.fieldsToPopulate = this.field.props?.['populates'];
    if (this.fieldsToPopulate?.length > 0) {
      const formRef = getPopulateReferenceForm(this.field, this.form);
      this.subscriptions.add(
        configureFields(this.field, this.fieldsToPopulate, formRef)
      );
    }
  }

  generateDefaultErrorMessages(): void {
    const defaultErrorMessages: Record<string, string> = {};

    defaultOtpDataFetchInputErrorMessages.forEach(
      (item: IIrembogovBasicLabelKeyPair<string>) => {
        if (!this.field.validation?.messages?.[item.key]) {
          defaultErrorMessages[item.key] = item.label;
        }
      }
    );
    if (!this.field.validation) {
      this.field.validation = {};
    }
    this.field.validation['show'] = true;
    if (!this.field.validation?.messages) {
      this.field.validation['messages'] = {};
    }
    this.field.validation.messages = {
      ...this.field.validation.messages,
      ...defaultErrorMessages,
    };
  }

  onDataFetch(value: string) {
    this.dataFetchedFailedError = false;
    this.isFetchingData = true;
    let { url } = this.field.props;
    const { endpointCode, payloadKey } = this.field.props;
    if (!url || !endpointCode || !payloadKey) {
      this.fieldStatus = EOtpDataFetchStatus.INPUT_OTP;
      this.isFetchingData = false;
      throw new Error(
        'url, endpointCode and payloadKey properties are required'
      );
    }

    if (this.field.props['useBaseUrl']) {
      url = updateUrlWithApiGatewayBaseUrl(url, this.environment);
    }

    this.fieldStatus = EOtpDataFetchStatus.INPUT_OTP;

    const params = {
      endpointCode,
      callerId: uuidv4(),
      payload: {
        [payloadKey]: value,
      },
      requester: uuidv4(),
    };
    this.subscriptions.add(
      this.http
        .post<Record<string, unknown>>(url, params)
        .pipe(
          finalize(() => {
            this.formControl.updateValueAndValidity();
            this.isFetchingData = false;
            this.cd.detectChanges();
          })
        )
        .subscribe({
          next: (res: Record<string, unknown>) => {
            this.dataFetchedFailedError = false;
            this.fieldStatus = EOtpDataFetchStatus.COMPLETED;

            const data = res['data'] as Record<string, unknown>;
            const response = data['response'] as string;

            this.formStateService.saveFetchedDataKeyInFormState(
              this.field,
              response
            );

            if (this.fieldsToPopulate.length > 0) {
              const formRef = getPopulateReferenceForm(this.field, this.form);
              populateFormFields(response, this.field, formRef);
            }
          },
          error: () => {
            this.fieldStatus = EOtpDataFetchStatus.INPUT_RECIPIENT;
            this.dataFetchedFailedError = true;
          },
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
