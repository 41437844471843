import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ILocale } from '../models/locale/locale.model';
import { IHttpPagedResponse } from '@irembo-andela/irembogov3-common';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private baseUrl = environment.apiGatewayBaseUrl + '/admin/v1';
  private languageChangedSubject = new BehaviorSubject<boolean>(false);
  languageChanged$ = this.languageChangedSubject.asObservable();

  loadingTranslations: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  supportedLocalesSubject: BehaviorSubject<ILocale[]> = new BehaviorSubject<
    ILocale[]
  >([]);
  supportedLocales$ = this.supportedLocalesSubject.asObservable();
  activeLocaleSubject: BehaviorSubject<ILocale> = new BehaviorSubject<ILocale>(
    {} as ILocale
  );
  activeLocale$ = this.activeLocaleSubject.asObservable();

  translationsLoadedBefore = false;

  constructor(private http: HttpClient) {}

  getSupportedLocales(): Observable<IHttpPagedResponse<ILocale>> {
    return this.http.get<IHttpPagedResponse<ILocale>>(
      `${this.baseUrl}/public/languages?page=0&size=50`
    );
  }

  triggerLanguageChange() {
    this.languageChangedSubject.next(true);
  }

  resetTrigger() {
    this.languageChangedSubject.next(false);
  }

  setSupportedLocales(locales: ILocale[]) {
    this.supportedLocalesSubject.next(locales);
  }

  setActiveLocale(locale: ILocale) {
    this.activeLocaleSubject.next(locale);
  }
}
