import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ILocale } from 'src/app/models/locale/locale.model';
import { LocaleService } from '../../../services/locale.service';

@Component({
  selector: 'irembogov-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() styling: 'NAVBAR' | 'MOBILE' | 'NONAVBAR' = 'NAVBAR';
  @Output() localeChanged = new EventEmitter<string>();

  activeLocale: ILocale = {} as ILocale;
  supportedLocales: ILocale[] = [];

  constructor(
    public translate: TranslateService,
    private localeService: LocaleService
  ) {}

  ngOnInit(): void {
    this.localeService.supportedLocales$.subscribe(locales => {
      this.supportedLocales = locales;
    });
    this.localeService.activeLocale$.subscribe(locale => {
      this.activeLocale = locale;
    });
  }

  changeLocale(locale: ILocale) {
    this.translate.use(locale.locale);

    localStorage.setItem('locale', locale.locale);
    localStorage.setItem('localeName', locale.nativeName);

    this.activeLocale = locale;
    this.localeService.setActiveLocale(locale);
    this.localeChanged.emit();
  }

  getStrippedLocale(locale: string): string {
    return locale === 'en' || locale === 'en-US'
      ? 'GB'
      : locale.indexOf('sw') >= 0
      ? 'TZ'
      : locale.includes('-')
      ? locale.split('-')[1]
      : locale;
  }
}
