import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { IdInputStatusEnum } from '../../../models/irembo-id-input-status.enum';
import { IEnvironment } from '../../../models/environment.model';
import {
  populateFormFields,
  resetFieldsToPopulate,
  updateUrlWithApiGatewayBaseUrl,
  getPopulateReferenceForm,
  subscribeToResetFieldFetchData,
} from '../../../../utils/utils/data-fetch-widget-utils';
import { FormArray, FormGroup } from '@angular/forms';
import { FormStateService } from '../../../services/formly/form-state.service';

@Component({
  selector: 'irembogov-custom-application-number-input',
  templateUrl: './custom-application-number-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomApplicationNumberInputComponent
  extends FieldType<FieldTypeConfig>
  implements OnDestroy, AfterViewInit
{
  private environment: IEnvironment;
  private subscriptions = new Subscription();
  statusClass: string | undefined;
  compareKeyValue: string | number | boolean | undefined;

  fieldsToPopulate: Record<string, string>[] = [];

  constructor(
    @Inject('environment') environment: IEnvironment,
    private http: HttpClient,
    private formStateService: FormStateService,
    private cd: ChangeDetectorRef
  ) {
    super();
    this.environment = environment;
  }

  ngAfterViewInit(): void {
    if (this.field.props?.['populates']) {
      if (!Array.isArray(this.field.props?.['populates'])) {
        throw new Error('populates should be an array');
      }
      this.fieldsToPopulate = this.field.props?.['populates'];
    }

    if (this.fieldsToPopulate.length > 0) {
      this.subscriptions.add(
        this.field.formControl.valueChanges
          .pipe(
            distinctUntilChanged(
              (a, b) => JSON.stringify(a) === JSON.stringify(b)
            )
          )
          .subscribe({
            next: () => {
              if (this.field.formControl.invalid) {
                const formRef = getPopulateReferenceForm(this.field, this.form);
                resetFieldsToPopulate(
                  this.fieldsToPopulate,
                  formRef,
                  this.formStateService
                );
              }
            },
          })
      );
    }

    this.subscriptions.add(
      subscribeToResetFieldFetchData(this.field, this.formStateService)
    );
  }

  onFetchData(applicationNo: string) {
    let { url } = this.field.props;
    if (!url) {
      throw new Error('url property is required');
    }
    if (this.field.props['useBaseUrl']) {
      url = updateUrlWithApiGatewayBaseUrl(url, this.environment);
    }

    this.statusClass = IdInputStatusEnum.VERIFYING;
    this.subscriptions.add(
      this.http
        .get<Record<string, unknown>>(url, {
          headers: { applicationNumber: applicationNo },
        })
        .subscribe({
          next: (res: Record<string, unknown>) => {
            this.statusClass = IdInputStatusEnum.SUCCESS;

            const data = res['data'] as Record<string, unknown>;
            const { details, ...others } = data;

            const newDataObject = {
              ...(details as Record<string, unknown>),
              ...others,
            };
            this.formStateService.saveFetchedDataKeyInFormState(
              this.field,
              newDataObject
            );

            if (this.fieldsToPopulate.length > 0) {
              const formRef: FormGroup<any> | FormArray<any> =
                getPopulateReferenceForm(this.field, this.form);
              populateFormFields(
                newDataObject,
                this.field,
                formRef,
                this.formStateService
              );
            }

            if (this.field.props?.['compareKey']) {
              const compareKey = this.field.props?.['compareKey'];
              const value = newDataObject[compareKey];

              if (
                typeof value === 'string' ||
                typeof value === 'number' ||
                typeof value === 'boolean'
              ) {
                this.compareKeyValue = value;
              }
            }
            this.cd.detectChanges();
          },
          error: () => {
            this.statusClass = IdInputStatusEnum.DANGER;
            this.cd.detectChanges();
          },
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
