import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Subscription, distinctUntilChanged, filter, debounceTime } from 'rxjs';
import { stringify } from 'flatted';
import { EIremboFormlyFormStateKeys } from '../../../models/irembo-formly-formstate-keys.enum';
import { FormStateService } from '../../../services/formly/form-state.service';
import { VerticalStepperService } from '../../../services/vertical-stepper/vertical-stepper.service';
import { EIremboFormlyFieldTypes } from '../../../config/formly-configs';
import { FormlyUtilsService } from '../../../services/formly/formly-utils.service';

@Component({
  selector: 'irembogov-rfa-readonly-checker-wrapper',
  templateUrl: './rfa-readonly-checker-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class RfaReadonlyCheckerWrapperComponent
  extends FieldWrapper
  implements OnInit, AfterViewInit, OnDestroy
{
  private fieldChangesSub: Subscription = new Subscription();
  private defaultReadOnly = false;

  constructor(
    private cd: ChangeDetectorRef,
    private formStateService: FormStateService,
    private verticalStepperService: VerticalStepperService,
    private formlyUtilsService: FormlyUtilsService
  ) {
    super();
  }

  ngOnInit(): void {
    if (
      this.field.props?.readonly &&
      !this.field.expressions?.['props.readonly']
    ) {
      this.defaultReadOnly = true;
    } else {
      this.defaultReadOnly = this.field.props?.readonly ?? false;
    }
  }

  ngAfterViewInit(): void {
    if (!this.formStateService.checkIfApplicationInAnRFAState()) {
      return;
    }

    this.fieldChangesSub.add(
      this.field.options?.fieldChanges
        ?.pipe(
          filter(e => {
            return e.type === 'valueChanges' && e.field.key === this.field.key;
          }),
          debounceTime(500),
          distinctUntilChanged((a, b) => stringify(a) === stringify(b))
        )
        .subscribe({
          next: () => {
            this.disableValidAndUnflaggedRfaFields();
          },
        })
    );

    this.fieldChangesSub?.add(
      this.formlyUtilsService.phoneNumberFieldInitialized$
        ?.pipe(
          filter((keyAndInitSuccess: { key: string; success: boolean }) => {
            return keyAndInitSuccess.key === <string>this.field.key;
          }),
          distinctUntilChanged((a, b) => stringify(a) === stringify(b))
        )
        .subscribe({
          next: () => {
            this.checkIfFieldKeyInRfaFlaggedFields();
          },
        })
    );

    this.fieldChangesSub?.add(
      this.verticalStepperService.selectedIndexObservable$.subscribe({
        next: () => {
          this.checkIfFieldKeyInRfaFlaggedFields();
        },
      })
    );
  }

  disableValidAndUnflaggedRfaFields() {
    if (!this.formStateService.checkIfApplicationInAnRFAState()) {
      return;
    }

    if (!this.field.props) {
      this.field.props = {};
    }

    if (!this.field?.formControl?.valid) {
      this.field.props.readonly = this.defaultReadOnly
        ? this.defaultReadOnly
        : false;
    }

    this.field.formControl?.updateValueAndValidity({
      onlySelf: true,
    });
    this.cd.detectChanges();
  }

  checkIfFieldKeyInRfaFlaggedFields() {
    if (!this.formStateService.checkIfApplicationInAnRFAState()) {
      return;
    }

    if (!this.field.props) {
      this.field.props = {};
    }

    const hasChildFieldFlaggedInRfa =
      this.checkIfCascadingDropdownHasFieldFlaggedInRfa();

    if (
      this.formState[EIremboFormlyFormStateKeys.RFA_FLAGGED_FIELDS]?.includes(
        this.field.key
      ) ||
      hasChildFieldFlaggedInRfa
    ) {
      this.field.props['rfaFlagged'] = true;
    } else {
      this.field.props['rfaFlagged'] = false;
    }

    this.field.props.readonly = this.defaultReadOnly
      ? this.defaultReadOnly
      : this.field?.formControl?.valid && !this.field.props['rfaFlagged'];

    this.field.formControl?.updateValueAndValidity({
      onlySelf: true,
    });
    this.cd.detectChanges();
  }

  private checkIfCascadingDropdownHasFieldFlaggedInRfa(): boolean {
    let hasFieldFlaggedInRFa = false;
    if (this.field.type !== EIremboFormlyFieldTypes.customcascadingdropdowns) {
      return hasFieldFlaggedInRFa;
    }

    const childKeys: string[] =
      this.field.props?.['configs']?.map(
        (config: Record<string, unknown>) => config['key']
      ) ?? [];

    childKeys.some(key => {
      if (
        this.formState[EIremboFormlyFormStateKeys.RFA_FLAGGED_FIELDS]?.includes(
          key
        )
      ) {
        hasFieldFlaggedInRFa = true;
        return true;
      }
      hasFieldFlaggedInRFa = false;
      return false;
    });

    return hasFieldFlaggedInRFa;
  }

  ngOnDestroy(): void {
    this.fieldChangesSub?.unsubscribe();
  }
}
