import { IService } from './../../models/services-list.model';
import { IFeatureService } from './../../models/featured-services-list.model';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { environment } from '../../../environments/environment';
import { NavigationExtras, Router } from '@angular/router';
import { PublicServicesService } from '../../services/public-services.service';

import { map } from 'rxjs';

@Component({
  selector: 'irembogov-find',
  templateUrl: './find.component.html',
  styleUrls: ['./find.component.scss'],
})
export class FindComponent implements OnInit {
  @ViewChild('search') searchEl: ElementRef | undefined;

  userProfile: KeycloakProfile | undefined;
  isLoggedIn = false;

  featuredServicesList: IFeatureService[] = [];
  selectedApplication!: IService;
  modalID!: ElementRef;
  selectedApplicantType = 'individual';
  heading = environment.homePage.title;
  subHeading = environment.homePage.subtitle;
  callToAction = environment.homePage.callToAction;
  actionLink = environment.homePage.actionLink;
  enableSearchBar = environment.enableSearchBar;

  constructor(
    private offcanvasService: NgbOffcanvas,
    private keycloak: KeycloakService,
    private modalService: NgbModal,
    private router: Router,
    private publicServicesService: PublicServicesService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoggedIn = await this.keycloak.isLoggedIn();

    if (this.isLoggedIn) {
      this.userProfile = await this.keycloak.loadUserProfile();
    }

    this.publicServicesService
      .getFeaturedServices()
      .pipe(map(res => res.data.content))
      .subscribe({
        next: res => {
          this.featuredServicesList = res;
        },
      });
  }

  logoutUser() {
    this.keycloak.logout(environment.authSuccessRedirectUrl);
  }

  openModal(modal: ElementRef, selectedService: IService) {
    this.selectedApplication = selectedService;
    this.modalService.open(modal, {
      size: 'lg',
      centered: true,
      animation: true,
    });
  }

  handleOnSelect(selectedItem: any) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        keyword: selectedItem.serviceName,
        id: selectedItem.id ?? selectedItem.serviceId,
      },
    };
    this.router.navigate(['/service/details'], navigationExtras);
  }

  handleApplicantTypeChange(view: string) {
    this.selectedApplicantType = view;
  }
}
