import { Component, ViewEncapsulation } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'irembogov-field-tabs',
  templateUrl: './custom-field-tabs.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: CdkStepper,
      useExisting: CustomFieldTabsComponent,
    },
  ],
})
export class CustomFieldTabsComponent extends FieldType {}
