<div *ngIf="verifyStep === 1">
  <ng-container [ngTemplateOutlet]="verificationForm"></ng-container>
</div>
<div *ngIf="verifyStep === 2">
  <ng-container [ngTemplateOutlet]="loader"></ng-container>
</div>
<div *ngIf="verifyStep === 3">
  <ng-container [ngTemplateOutlet]="verifyPhone"></ng-container>
</div>
<div *ngIf="verifyStep === 4">
  <ng-container [ngTemplateOutlet]="identityVerified"></ng-container>
</div>

<ng-template #verificationForm>
  <div
    class="verify-identity-container bg-white p-3 d-flex flex-column col-12 justify-content-start align-items-start justify-content-md-center align-items-md-center">
    <div
      class="heading text-start text-md-center text-md-center col-12 col-md-6 col-lg-4">
      <div
        class="error-message rounded-3 bg-danger text-white p-2 text-center mb-3 text-start"
        *ngIf="errorMessage">
        <i class="fa fa-info-circle me-1" aria-hidden="true"></i>
        {{ errorMessage | translate }}
      </div>
      <img
        class="mb-3 mb-lg-5"
        src="../../../assets/img/logo.png"
        alt="irembogov logo"
        width="109"
        height="20" />
      <h3>{{ 'Verify your identity' | translate }}</h3>
      <p class="sub-heading" [innerHTML]="subHeadingText | translate"></p>
    </div>
    <div class="verify-form text-start col-12 col-md-6 col-lg-4">
      <form [formGroup]="verifyForm" (ngSubmit)="initiateVerify()">
        <div class="form-group mb-2">
          <label for="phone">{{ 'Phone number' | translate }}</label>
          <input
            type="tel"
            formControlName="phone"
            class="form-control"
            id="phone"
            placeholder="{{ 'Phone number' | translate }}" />
          <ng-template #phoneInfo>
            <small class="mt-1">
              {{
                'This should be the same phone number registered with your NID.'
                  | translate
              }}
            </small>
          </ng-template>
          <ng-container
            *ngIf="
              verifyForm.controls['phone'].invalid &&
                (verifyForm.controls['phone'].dirty ||
                  verifyForm.controls['phone'].touched);
              else phoneInfo
            ">
            <small
              class="mt-1 text-danger"
              *ngIf="verifyForm.controls['phone'].errors?.['minlength'] || verifyForm.controls['phone'].errors?.['maxlength']">
              {{ 'Phone Number (Rwanda) must be 10 digits' | translate }}.
            </small>
            <small
              class="mt-1 text-danger"
              *ngIf="verifyForm.controls['phone'].errors?.['pattern']">
              {{ 'Phone Number must contain numbers only' | translate }}.
            </small>
          </ng-container>
        </div>
        <div class="form-group mb-4">
          <label for="identificationNumber">{{
            'National ID' | translate
          }}</label>
          <input
            type="text"
            formControlName="identificationNumber"
            class="form-control"
            id="identificationNumber"
            placeholder="{{ 'National ID' | translate }}" />
          <ng-container
            *ngIf="
              verifyForm.controls['identificationNumber'].invalid &&
              (verifyForm.controls['identificationNumber'].dirty ||
                verifyForm.controls['identificationNumber'].touched)
            ">
            <small
              class="mt-1 text-danger"
              *ngIf="verifyForm.controls['identificationNumber'].errors?.['minlength'] || verifyForm.controls['identificationNumber'].errors?.['maxlength']">
              {{ 'ID Number must be 16 digits' | translate }}.
            </small>
            <small
              class="mt-1 text-danger"
              *ngIf="verifyForm.controls['identificationNumber'].errors?.['pattern']">
              {{ 'ID Number must contain numbers only' | translate }}.
            </small>
          </ng-container>
        </div>
        <div class="hstack gap-2">
          <button class="btn cancel-button w-50" (click)="cancelVerification()">
            {{ 'Cancel' | translate }}
          </button>
          <button
            class="btn btn-primary fw-semibold w-50"
            type="submit"
            [disabled]="isLoading || verifyForm.invalid">
            <div
              class="spinner-border spinner-border-sm text-white"
              role="status"
              *ngIf="isLoading">
              <span class="sr-only">Loading...</span>
            </div>
            {{ 'Continue' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #loader>
  <div
    class="d-flex flex-column justify-content-center align-items-center min-vh-100">
    <div class="loader"></div>
  </div>
</ng-template>

<ng-template #verifyPhone>
  <irembogov-verify-phone-number
    [phoneNumber]="verifyForm.get('phone')?.value"
    (resendOtp)="initiateVerify()"
    (phoneVerified)="onPhoneVerification()"
    (goBack)="verifyStep = 1"></irembogov-verify-phone-number>
</ng-template>

<ng-template #identityVerified>
  <div
    class="verify-identity-container d-flex flex-column justify-content-center align-items-center min-vh-100 bg-white">
    <div
      class="d-flex flex-column justify-content-start justify-content-lg-center justify-content-md-center align-items-start align-items-lg-center align-items-md-center p-3 min-vh-100 text-start text-lg-center text-md-center col-12 col-lg-4 col-md-6">
      <img
        class="mb-5"
        src="../../../assets/img/logo.png"
        alt="irembogov logo"
        width="109"
        height="20" />

      <img
        class="mb-2"
        src="../../../assets/img/success.png"
        alt="success"
        height="56"
        width="56" />
      <h3 class="mb-2">{{ 'Identity verified!' | translate }}</h3>
      <p class="sub-heading mb-3">
        {{ 'your account has been successfully verified.' | translate }}
      </p>
      <button
        class="btn btn-primary fw-semibold w-100"
        (click)="gotToHomePage()">
        {{ 'Go to homepage ' | translate }}
      </button>
    </div>
  </div>
</ng-template>
