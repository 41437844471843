import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, NavigationStart } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Location, PopStateEvent } from '@angular/common';
import { LocaleService } from './services/locale.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'irembogov-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'citizen-portal';
  private navSub: Subscription | undefined;
  private lastPoppedUrl: string | undefined;
  private yScrollStack: number[] = [];
  isLoadingTranslations = false;
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private location: Location,
    private localeService: LocaleService,
    private translate: TranslateService
  ) {
    this.translate.setDefaultLang(
      localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE
    );
  }

  ngOnInit(): void {
    this.localeService.loadingTranslations.subscribe(status => {
      this.isLoadingTranslations = status;
    });

    this.navSub = this.router.events.subscribe((event: unknown) => {
      if (event instanceof NavigationEnd) {
        this.modalService.dismissAll();
      }
    });

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationStart) {
        if (ev.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (ev instanceof NavigationEnd) {
        if (ev.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, <number>this.yScrollStack.pop());
        } else window.scrollTo(0, 0);
      }
    });
  }

  ngOnDestroy(): void {
    this.navSub?.unsubscribe();
  }
}
