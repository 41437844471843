import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { IdInputStatusEnum } from '../../../models/irembo-id-input-status.enum';
import { FormArray, FormGroup } from '@angular/forms';
import { IEnvironment } from '../../../models/environment.model';
import {
  IIdentityFieldValue,
  TDoubleIdentityFormControlValue,
} from '../../../models/citizen-document-types.enum';
import {
  resetFieldsToPopulate,
  updateUrlWithApiGatewayBaseUrl,
  getPopulateReferenceForm,
  populateFormFields,
  subscribeToResetFieldFetchData,
  updateUrlWithMockBaseUrl,
} from '../../../../utils/utils/data-fetch-widget-utils';
import { v4 as uuidv4 } from 'uuid';
import { FormStateService } from '../../../services/formly/form-state.service';

@Component({
  selector: 'irembogov-custom-double-id-input',
  templateUrl: './custom-double-id-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDoubleIdInputComponent
  extends FieldType<FieldTypeConfig>
  implements OnDestroy, AfterViewInit, OnInit
{
  private environment: IEnvironment;
  private subscriptions = new Subscription();
  statusClass: IdInputStatusEnum | undefined;

  firstIdentityDocumentTypeKey!: string;
  firstIdentityValueKey!: string;
  firstIdentityLabel!: string;
  firstIdentityPlaceholder!: string;
  firstIdentityCountryValueKey!: string;

  secondIdentityDocumentTypeKey!: string;
  secondIdentityValueKey!: string;
  secondIdentityLabel!: string;
  secondIdentityPlaceholder!: string;
  secondIdentityCountryValueKey!: string;

  otherFieldsToPopulate: Record<string, string>[] = [];

  constructor(
    @Inject('environment') environment: IEnvironment,
    private http: HttpClient,
    private formStateService: FormStateService,
    private cd: ChangeDetectorRef
  ) {
    super();
    this.environment = environment;
  }

  ngOnInit(): void {
    this.presetPropsValues();
  }

  ngAfterViewInit(): void {
    if (this.field.props?.['populates']) {
      if (!Array.isArray(this.field.props?.['populates'])) {
        throw new Error(
          'populates props in customdoubleidinput  should be an array'
        );
      }
      this.otherFieldsToPopulate = this.field.props?.['populates'];
    }

    this.subscriptions.add(
      subscribeToResetFieldFetchData(this.field, this.formStateService)
    );

    if (this.otherFieldsToPopulate.length > 0) {
      this.subscriptions.add(
        this.field.formControl.valueChanges
          .pipe(
            distinctUntilChanged(
              (a, b) => JSON.stringify(a) === JSON.stringify(b)
            )
          )
          .subscribe({
            next: () => {
              if (this.field.formControl.invalid) {
                const populateForm: FormGroup<any> | FormArray<any> =
                  getPopulateReferenceForm(this.field, this.form);
                resetFieldsToPopulate(this.otherFieldsToPopulate, populateForm);
              }
            },
          })
      );
    }
  }

  private checkInputIdentityValues(
    identityValue?: IIdentityFieldValue
  ): boolean {
    if (
      identityValue?.idCountry &&
      identityValue?.idLabel &&
      identityValue?.idType &&
      identityValue?.idValue
    ) {
      return true;
    }
    return false;
  }

  presetPropsValues() {
    this.firstIdentityDocumentTypeKey =
      this.field.props?.['firstIdentityDocumentTypeKey'] ?? 'firstDocumentType';

    this.firstIdentityValueKey =
      this.field.props?.['firstIdentityValueKey'] ?? 'firstDocumentNumber';

    this.firstIdentityLabel =
      this.field.props?.['firstIdentityLabel'] ?? 'First Identity';

    this.firstIdentityPlaceholder =
      this.field.props?.['firstIdentityPlaceholder'] ??
      `Fill in ${this.firstIdentityLabel}`;

    this.firstIdentityCountryValueKey =
      this.field.props?.['firstIdentityCountryValueKey'] ??
      `firstIdentityCountry`;

    this.secondIdentityDocumentTypeKey =
      this.field.props?.['secondIdentityDocumentTypeKey'] ??
      'secondDocumentType';

    this.secondIdentityValueKey =
      this.field.props?.['secondIdentityValueKey'] ?? 'secondDocumentNumber';

    this.secondIdentityLabel =
      this.field.props?.['secondIdentityLabel'] ?? 'Second Identity';

    this.secondIdentityPlaceholder =
      this.field.props?.['secondIdentityPlaceholder'] ??
      `Fill in ${this.secondIdentityLabel}`;

    this.secondIdentityCountryValueKey =
      this.field.props?.['secondIdentityCountryValueKey'] ??
      `secondIdentityCountry`;
  }

  onFetchDataFromApi(requestData: TDoubleIdentityFormControlValue | null) {
    let { url } = this.field.props;
    const { endpointCode } = this.field.props;
    if (!endpointCode) {
      throw new Error('endpointCode is required in field config props');
    }
    if (!url) {
      throw new Error('url property is required');
    }
    if (
      !(
        this.firstIdentityDocumentTypeKey &&
        this.firstIdentityValueKey &&
        this.secondIdentityDocumentTypeKey &&
        this.secondIdentityValueKey &&
        requestData?.[this.firstIdentityValueKey] &&
        requestData?.[this.secondIdentityValueKey]
      )
    ) {
      return;
    }

    if (
      this.field.props['useBaseUrl'] &&
      !this.field.options?.formState?.useMock
    ) {
      url = updateUrlWithApiGatewayBaseUrl(url, this.environment);
    }
    if (this.field.options?.formState?.useMock) {
      url = updateUrlWithMockBaseUrl(url, this.environment);
    }
    const payload: Record<string, string | string[] | undefined> = {};

    payload[this.firstIdentityDocumentTypeKey] =
      requestData?.[this.firstIdentityValueKey]?.idType;
    payload[this.firstIdentityValueKey] =
      requestData?.[this.firstIdentityValueKey]?.idValue;
    payload[this.firstIdentityCountryValueKey] = <string>(
      requestData?.[this.firstIdentityValueKey]?.idCountry?.['value']
    );

    payload[this.secondIdentityDocumentTypeKey] =
      requestData?.[this.secondIdentityValueKey]?.idType;
    payload[this.secondIdentityValueKey] =
      requestData?.[this.secondIdentityValueKey]?.idValue;
    payload[this.secondIdentityCountryValueKey] = <string>(
      requestData?.[this.secondIdentityValueKey]?.idCountry?.['value']
    );

    const params = {
      endpointCode,
      callerId: uuidv4(),
      payload,
      requester: uuidv4(),
    };

    this.statusClass = IdInputStatusEnum.VERIFYING;
    this.subscriptions.add(
      this.http.post<Record<string, unknown>>(url, params).subscribe({
        next: (res: Record<string, unknown>) => {
          this.statusClass = IdInputStatusEnum.SUCCESS;
          const data = res['data'] as Record<string, unknown>;
          const crvsResponse: string = data['response'] as string;
          this.formStateService.saveFetchedDataKeyInFormState(
            this.field,
            crvsResponse
          );
          if (this.otherFieldsToPopulate.length > 0) {
            const populateForm: FormGroup<any> | FormArray<any> =
              getPopulateReferenceForm(this.field, this.form);
            populateFormFields(crvsResponse, this.field, populateForm);
          }
          this.cd.detectChanges();
        },
        error: () => {
          this.statusClass = IdInputStatusEnum.DANGER;
          this.cd.detectChanges();
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
