<div [ngClass]="wapperTopPaddingClass">
  <ng-container *ngIf="tableTitle">
    <h4 class="font-weight-bold">{{ tableTitle }}</h4>
  </ng-container>
  <ng-container>
    <div class="quick-actions d-flex justify-content-between">
      <div class="d-flex flex-column align-items-stretch">
        <ng-container *ngIf="isSortVisible">
          <irembogov-sort-options-dropdown
            [sortLabel]="sortLabel"
            [selectedSortField]="selectedSortField"
            [sortFieldOptions]="!!sortFieldOptions ? sortFieldOptions : []"
            (OnSelectSortField)="
              onSortChange($event)
            "></irembogov-sort-options-dropdown>
        </ng-container>
        <ng-content select="[leftHeader]"></ng-content>
      </div>
      <div class="d-flex flex-column align-items-stretch">
        <span class="search-label" *ngIf="searchLabel && searchLabel !== ''">
          {{ searchLabel }}
        </span>
        <div *ngIf="isSeachVisible" class="d-flex align-items-stretch">
          <label class="search-field-control-label" for="searchFieldControl">
            <input
              type="search"
              id="searchFieldControl"
              class="form-control d-flex form-control-sm search-field-control"
              [placeholder]="searchPlaceholder"
              [formControl]="searchFieldControl" />
          </label>
        </div>
        <ng-content select="[rightHeader]"></ng-content>
      </div>
    </div>
  </ng-container>
  <div *ngIf="loading; else showTableContent">
    <div class="bg-white pb-4 card my-4">
      <div class="row pt-4 px-4" *ngFor="let item of shimmerTableSize">
        <div class="col-sm-0 col-2 circle loading-shimmer"></div>
        <div class="col-sm-2 col-2 line loading-shimmer"></div>
        <div class="col-sm-2 col-2 line loading-shimmer"></div>
        <div class="col-sm-2 col-2 line loading-shimmer"></div>
        <div class="col-sm-2 col-2 line loading-shimmer"></div>
        <div class="col-sm-2 col-2 line loading-shimmer"></div>
      </div>
    </div>
  </div>
  <ng-template #showTableContent>
    <div class="{{ wapperTopMarginClass }} table-container-light">
      <div [ngClass]="{ 'table-rounded-corners': roundedTable }">
        <ng-content select="[body]"></ng-content>
        <ng-container *ngIf="paginationInsideTable">
          <irembogov-custom-pagination
            [collectionSize]="totalItems"
            [pageSizes]="pageSizes"
            [pageSize]="itemsPerPage"
            [page]="currentPage"
            (paginatedValues)="onPageChange($event)"
            [showDropdown]="showDropdown"
            [maxSize]="maxSize"
            [paginationDisplaySize]="paginationDisplaySize"
            [minimalPagination]="minimalPagination"
            [showDirectionText]="
              showDirectionText
            "></irembogov-custom-pagination>
        </ng-container>
      </div>
    </div>
  </ng-template>

  <ng-container *ngIf="(!roundedTable && !loading) || paginationVisible">
    <!-- Paginator -->
    <div class="d-flex flex-row-reverse mt-4">
      <irembogov-pagination
        [collectionSize]="totalItems"
        [pageSizes]="pageSizes"
        [pageSize]="itemsPerPage"
        [page]="currentPage"
        (paginatedValues)="onPageChange($event)"
        [showDropdown]="showDropdown"
        [maxSize]="maxSize"
        [paginationDisplaySize]="paginationDisplaySize"
        [minimalPagination]="minimalPagination"
        [showDirectionText]="showDirectionText"></irembogov-pagination>
    </div>
  </ng-container>
</div>
